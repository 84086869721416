import { FC } from "react";
import { LoginHeader } from "../../modules/landing/navigation/login-header";
import "./Layout.scss";

export const Layout: FC = ({ children }) => {
  return (
    <div className="wrapper">
      <LoginHeader />

      {children}
    </div>
  );
};
