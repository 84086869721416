import API from "../../API";
import { useQuery } from "react-query";

export const useNarrative = () => {
  return useQuery({
    queryKey: ["narrative"],
    queryFn: async () => {
      const response = await API.get("narrative");

      return response.data;
    },
  });
};

export const getSortedValueDrivers = (values?: any[]) => {
  const valuesByFrequency = values?.reduce((acc: any, cur: any[]) => {
    cur?.forEach((value) => {
      if (!value) {
        return acc;
      }

      const value_ = acc[value];

      if (value_ === undefined) {
        acc[value] = 1;
      }

      if (value_) {
        acc[value] += 1;
      }
    });

    return acc;
  }, []);

  return Object.entries(valuesByFrequency || [])
    .sort(
      //@ts-ignore
      (a, b) => b[1] - a[1]
    )
    ?.map((v) => ({ name: v[0], value: v[1] }))
    ?.splice(0, 9);
};
