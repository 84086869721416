import { FC } from "react";
import "./coupon-management-page.scss";

type Props = {
  usedBy: {
    firstName: string;
    lastName: string;
    email: string;
    dateUsed: string;
    dateUsedShort: string;
  }[];
};

export const CouponUsedBy: FC<Props> = ({ usedBy }) => {
  return (
    <div>
      {!usedBy.length && "This coupon was not used yet."}

      {usedBy
        ?.sort((a, b) => {
          // @ts-ignore
          return new Date(a.dateUsedShort) - new Date(b.dateUsedShort);
        })
        ?.map((u) => (
          <div className="couponUsedBy_row">
            <div className="couponUsedBy_column">
              <div
                className="couponUsedBy_name"
                title={`${u.firstName} ${u.lastName}`}
              >
                {`${u.firstName} ${u.lastName}`}
              </div>
              <div className="couponUsedBy_email" title={u.email}>
                {u.email}
              </div>
            </div>
            <div title={u.dateUsed}>{u.dateUsedShort}</div>
          </div>
        ))
        ?.reverse()}
    </div>
  );
};
