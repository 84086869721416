import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { userActions } from "../../../actions/login.action";
import { RootState } from "../../../app/store";

import "./navigation-bar.scss";

//This element lines up with the width of the ontent on each page, therefore it should be placed within the
//main div of each page to line up the spacing (i saw in the authenticated app.js route, it was placed external to the displayed components)

const mapState = (state: RootState) => {
  const { user } = state.authentication;
  return { user };
};

const actionCreators = {
  logout: userActions.logout,
};

const connector = connect(mapState, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface LoginHeaderProps {
  name?: string;
}

const LoginHeader: React.FC<PropsFromRedux & LoginHeaderProps> = (props) => {
  const { user, logout } = props;
  const location = useLocation();
  let paths: string[] = location.pathname.split("/");

  paths.shift();

  const updatePaths = () => {
    paths.map((value, index) => {
      if (parseInt(value) && props.name) {
        paths[index] = props.name;
      }
    });
  };
  updatePaths();

  const path_to_crumb: any = {
    classify: " Classify",
    "user-management": "Users ",
    "content-management": "Content ",
    "leadership-2021-campaign": " Leadership 2021 Campaign",
    users: " Users",
  };

  let currentpath: string = "";

  console.log(paths);

  return (
    <div className={`breadcrumb-wrapper` + (paths.length > 1 ? "" : " single")}>
      {paths.length > 1 && (
        <div className="breadcrumb">
          {paths.map((value, index) => {
            currentpath = currentpath + "/" + value;
            if (index < paths.length - 1) {
              return (
                <span key={`nav-${index}`}>
                  <NavLink to={`${currentpath}`}>
                    {path_to_crumb[value]
                      ? path_to_crumb[value]
                      : value.charAt(0).toUpperCase() + value.slice(1)}
                  </NavLink>{" "}
                  {" > "}
                </span>
              );
            } else {
              if (value !== "") {
                return (
                  <span id="final-crumb" key={`nav-${index}`}>
                    {path_to_crumb[value]
                      ? path_to_crumb[value]
                      : value.charAt(0).toUpperCase() + value.slice(1)}
                  </span>
                );
              }
            }
          })}
        </div>
      )}
      <div className="logged-in-text">
        You are logged in as <span>{user?.fullName}</span> /{" "}
        <Link to="/auth" onClick={logout}>
          Logout
        </Link>
      </div>
    </div>
  );
};

const connectedLoginHeader = connector(LoginHeader);
export { connectedLoginHeader as LoginHeader };
