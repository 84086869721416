import { getAllCoupons } from "../../services/coupon.service";
import { useQuery } from "react-query";

export const useCoupons = () => {
  return useQuery({
    queryKey: ["coupons"],
    queryFn: async () => {
      const response = await getAllCoupons();

      return response;
    },
  });
};
