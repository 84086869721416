import { connect, ConnectedProps } from "react-redux";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { RootState } from "../../app/store";
import { ForgotPasswordPage } from "./forgot-password/forgot-password-page";
import { LoginPage } from "./login/login-page";
import { RecoverPasswordPage } from "./recover-password/recover-password-page";

import React from "react";

const mapState = (state: RootState) => {
  const { authenticated } = state.authentication;
  return { authenticated };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

const LandingPage: React.FC<PropsFromRedux> = (props) => {
  let match = useRouteMatch();

  if (props.authenticated) {
    return (
      <Switch>
        <Route path={match.path}>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path={`${match.path}/forgot-password`}>
          <ForgotPasswordPage />
        </Route>
        <Route path={`${match.path}/reset-password`}>
          <RecoverPasswordPage />
        </Route>
        <Route path={match.path}>
          <LoginPage />
        </Route>
      </Switch>
    );
  }
};

const connectedLandingPage = connector(LandingPage);
export { connectedLandingPage as LandingPage };
