import React, { FC, useCallback, useEffect, useState } from "react";
import "./coupon-management-page.scss";
import {
  DeleteValueModal,
  MessageBox,
} from "../../helpers/message-box/message-box";
import { statusToVariant } from "../../../dtos/error.dto";
import { useErrorHandler } from "../../../hooks/error-hook";
import { ICouponDto } from "../../../dtos/coupon.dto";
import { Button, Dropdown } from "react-bootstrap";
import {
  createCoupon,
  deleteCoupon,
  patchCoupon,
} from "../../../services/coupon.service";
import { CouponUsedBy } from "./CouponUsedBy";
import API from "../../../API";
import { IOrderDto } from "../../../dtos/order.dto";
import { Layout } from "../../../components/Layout/Layout";
import { useCoupons } from "../../coupon/coupon.query";
import { useOrders } from "../../order/order.fn";
import { useQueryClient } from "react-query";
export const CouponManagementPage: React.FC<{}> = () => {
  const [deleteArray, setDeleteArray] = useState<Array<number>>([]);
  const [added, setAdded] = useState<boolean>(false);
  const { httpError, updateHttpError } = useErrorHandler();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: coupons } = useCoupons();

  const handleCheck = (id: number, checked: boolean) => {
    let newChecks = [...deleteArray];
    if (!checked) {
      if (newChecks.includes(id)) {
        newChecks = newChecks.filter((check) => check !== id);
      } else {
        newChecks.push(id);
      }
    } else {
      newChecks = newChecks.filter((check) => check != id);
    }

    setDeleteArray([...newChecks]);
  };

  const selectAll = (checked: boolean) => {
    let newChecks = [...deleteArray];
    if (checked) {
      coupons?.forEach((value) => {
        if (!deleteArray.includes(value.id)) {
          newChecks.push(value.id);
        }
      });
    } else {
      newChecks = [];
    }

    setDeleteArray([...newChecks]);
  };

  const deleteUsers = async () => {
    Promise.all(
      deleteArray.map((value) => {
        return deleteCoupon(value);
      })
    ).then((value) => {
      value.forEach((value) => {
        if (value.statusCode > 399) {
          updateHttpError({
            message: value.message,
            statusCode: value.statusCode,
          });
        }
      });
      setDeleteArray([]);
      setOpenModal(false);
      setAdded(!added);
      setTimeout(() => {
        updateHttpError({ message: "", statusCode: 0 });
      }, 3000);
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Layout>
      {openModal && (
        <DeleteValueModal
          category={"coupon"}
          value_ids={deleteArray}
          onClose={handleCloseModal}
          onDelete={deleteUsers}
        />
      )}

      <TitleRow title={"Coupon Management"} />
      {httpError.statusCode > 399 && (
        <MessageBox
          message={httpError.message}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}

      {coupons && (
        <form className="user-management-form">
          <Table
            data={coupons}
            handleCheck={handleCheck}
            deleteArray={deleteArray}
            selectAll={selectAll}
          />
        </form>
      )}

      {deleteArray.length > 0 && (
        <span
          onClick={() => {
            setOpenModal(true);
          }}
          id="delete"
        >
          Delete
        </span>
      )}

      <AddNewCoupon />
    </Layout>
  );
};

export interface TitleRowProps {
  title: string;
}

export const TitleRow: React.FC<TitleRowProps> = ({ title }) => {
  return (
    <div className="title-row-flex-wrapper">
      <h1 className="title">{title}</h1>
    </div>
  );
};

interface TableProps {
  data: ICouponDto[];
  deleteArray: Array<number>;
  handleCheck(id: number, checked: boolean): any;
  selectAll(checked: boolean): any;
}
export const Table: React.FC<TableProps> = (props) => {
  const [checkedAll, setCheckedAll] = useState(false);

  const { orders } = useOrders();

  const handleCheckedAll = () => {
    setCheckedAll(!checkedAll);
  };

  useEffect(() => {
    props.selectAll(checkedAll);
  }, [checkedAll]);
  useEffect(() => {
    setCheckedAll(false);
  }, [props.data]);

  return (
    <table>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              id="selectall"
              onChange={handleCheckedAll}
              checked={checkedAll}
            />
          </th>
          <th>CODE</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((value, index) => {
          return (
            <TableRow
              code={value.code}
              key={`user-${value.id}`}
              id={value.id}
              handleCheck={props.handleCheck}
              checked={props.deleteArray.includes(value.id)}
              enabled={value.enabled}
              orders={orders}
            />
          );
        })}
      </tbody>
    </table>
  );
};

interface TableRowProps {
  code: string;
  checked: boolean;
  id: number;
  enabled: boolean;
  orders: IOrderDto[];
  handleCheck(id: number, checked: boolean): any;
}

const TableRow: React.FC<TableRowProps> = ({
  code,
  checked,
  id,
  enabled,
  orders,
  handleCheck,
}) => {
  const [users, setUsers] = useState<any[]>([]);
  const [usedBy, setUsedBy] = useState<
    {
      firstName: string;
      lastName: string;
      email: string;
      dateUsed: string;
      dateUsedShort: string;
    }[]
  >([]);

  // Filter orders by the coupon used
  const couponOrders = orders.filter(
    (o) => parseInt(o.processorLog.message?.replace(/[^0-9]/g, "") || "") === id
  );

  const userIds = couponOrders.map((o) => o.ownerId);

  const uniqueUserIds = userIds.filter((u, i) => {
    if (userIds.indexOf(u) === i) {
      return u;
    }
  });

  const getUsers = useCallback(async () => {
    if (!uniqueUserIds.length) {
      return;
    }

    const users = await API.get(`users/search/?ids=${uniqueUserIds.join(",")}`);

    const usedBy = couponOrders.map((o) => {
      const userInfo = users.data.find((u: any) => u.id === o.ownerId);

      return {
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        email: userInfo?.email,
        dateUsed: new Date(o.timestamp).toLocaleString(),
        dateUsedShort: new Date(o.timestamp).toLocaleDateString(),
      };
    });

    setUsers(users.data);
    setUsedBy(usedBy);
  }, [uniqueUserIds, couponOrders]);

  useEffect(() => {
    if (!users.length) {
      getUsers();
    }
  }, [users.length, getUsers]);

  const Checked = () => {
    handleCheck(id, checked);
  };

  const [isEnabled, setIsEnabled] = useState<boolean>(enabled);

  const toggleEnabled = async () => {
    const response = await patchCoupon(id, { enabled: !isEnabled });
    if (response.statusCode > 299) {
      alert("An error occured. Please try again later.");
    } else {
      setIsEnabled(!isEnabled);
    }
  };

  return (
    <tr>
      <td>
        <input
          onChange={() => {
            Checked();
          }}
          type="checkbox"
          checked={checked}
        ></input>
      </td>

      <td id="code">
        <div className="couponUsedBy_column">
          {code.toUpperCase()}

          <div title={`Times used: ${couponOrders.length}`}>
            {couponOrders.length}
            {!!couponOrders.length && (
              <span style={{ marginLeft: 4 }}>&#9989;</span>
            )}
          </div>

          {!!uniqueUserIds.length && (
            <div
              title={`Unique users: ${uniqueUserIds.length}`}
              style={{ marginLeft: 5 }}
            >
              {uniqueUserIds.length}
              {<span style={{ marginLeft: 3 }}>&#129333;</span>}
            </div>
          )}
        </div>
      </td>

      <td>
        <Dropdown>
          <Dropdown.Toggle>Used by:</Dropdown.Toggle>
          <Dropdown.Menu style={{ width: 385, padding: 20, top: 2, left: -2 }}>
            <CouponUsedBy usedBy={usedBy} />
          </Dropdown.Menu>
        </Dropdown>
      </td>

      <td>
        {!isEnabled && <Button onClick={toggleEnabled}>INACTIVE</Button>}
        {isEnabled && <Button onClick={toggleEnabled}>ACTIVE</Button>}
      </td>
    </tr>
  );
};

export const AddNewCoupon: FC = () => {
  const queryClient = useQueryClient();

  const [input, setInput] = useState<string>("");

  const submitFunction = async () => {
    try {
      await createCoupon({ code: input });

      setInput("");

      queryClient.invalidateQueries(["coupons"]);
    } catch (err: any) {
      alert(err.messages);
    }
  };

  const updateInputValue = (e: any) => {
    setInput(e.target.value.replace(/[\W_]+/g, "").toUpperCase());
  };

  return (
    <React.Fragment>
      <div className="float-right" style={{ marginTop: 20 }}>
        <input
          type="text"
          placeholder={`Coupon Code`}
          value={input}
          onChange={updateInputValue}
          className="edit-spacing"
          style={{ marginRight: 10 }}
        />
        <button className="edit-spacing" onClick={submitFunction}>
          ADD
        </button>
      </div>
    </React.Fragment>
  );
};
