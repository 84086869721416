import { FormikProps, Form, withFormik } from "formik";
import { Button, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { requestPasswordLink } from "../../../services/user.service";
import { MessageBox, FormMessages } from "../../helpers";
import { ArrowIcon } from "../../../Icons";

interface FormValues {
  email: string;
}

const InnerForm = (props: FormikProps<FormValues>) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    status,
  } = props;

  return (
    <Form noValidate>
      {status._errors && (
        <FormMessages variant="danger" messages={status._errors} />
      )}

      {status._success && (
        <MessageBox
          variant="success"
          message="Please check your inbox for recovery instructions - Redirecting you to log in page"
        />
      )}

      <FormGroup>
        <FormLabel>Email :</FormLabel>
        <FormControl
          type="text"
          name="email"
          placeholder="Email"
          className={touched.email && errors.email ? "error" : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        {touched.email && errors.email && (
          <div className="error-message">{errors.email}</div>
        )}
      </FormGroup>

      <div className="login-button-flex">
        <span>
          Remembered your password? <Link to="/"> Login</Link>
        </span>

        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Submit
          <ArrowIcon direction="left" />
        </Button>
      </div>
    </Form>
  );
};

const OuterForm = withFormik<{}, FormValues>({
  mapPropsToStatus: (props) => {
    return {
      _errors: null,
      _success: false,
    };
  },

  mapPropsToValues: (props) => {
    return {
      email: "", //TODO: remove this and set to blank
    };
  },

  validationSchema: () => {
    const schema = Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .email("Not a valid email address"),
    });

    return schema;
  },

  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    try {
      await requestPasswordLink(values.email);
      setStatus({ _success: true });
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_ADMIN_URL!}/auth`;
      }, 4000);
    } catch (e: any) {
      setStatus({ _errors: e.messages });
    }
    setSubmitting(false);
  },
})(InnerForm);

export { OuterForm as ForgotPasswordForm };
