import { useEffect, useState } from "react";
import { IOrderDto } from "../../dtos/order.dto";
import API from "../../API";

export const useOrders = () => {
  const [orders, setOrders] = useState<IOrderDto[]>([]);

  const getOrders = async () => {
    if (!orders.length) {
      const result = await API.get("orders/");

      setOrders(result.data);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  return { orders };
};
