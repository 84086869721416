export const getUsersByMonth = (users: any[] | undefined) => {
  return users?.reduce((users, user) => {
    const date = new Date(user?.createdAt);

    if (date.toString() === "Invalid Date") {
      return users;
    }

    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const entry = `${month} ${year}`;

    const found = users?.find((u: any) => u?.month === entry);

    if (!found) {
      users.push({ month: entry, Users: 1 });

      return users;
    }

    found.Users++;

    return users;
  }, [] as any);
};

export const getTimeToCompleteSorvey = (narrativeData: any[]) => {
  return narrativeData?.reduce(
    (acc, current) => {
      const createdAt = new Date(current?.createdAt);
      const updatedAt = new Date(current?.updatedAt);

      const diff = updatedAt.getTime() - createdAt.getTime();

      const minutes = Math.round(diff / 60000);

      if (minutes > 60) {
        const _ = acc.find((a: any) => a.name === "above 60 min");

        _.value++;

        return acc;
      }

      if (minutes > 0 && minutes <= 10) {
        const _ = acc.find((a: any) => a.name === "0 - 10 min");

        _.value++;

        return acc;
      }

      if (minutes > 10 && minutes <= 20) {
        const _ = acc.find((a: any) => a.name === "10 - 20 min");

        _.value++;

        return acc;
      }

      if (minutes > 20 && minutes <= 30) {
        const _ = acc.find((a: any) => a.name === "20 - 30 min");

        _.value++;

        return acc;
      }

      if (minutes > 30 && minutes <= 40) {
        const _ = acc.find((a: any) => a.name === "30 - 40 min");

        _.value++;

        return acc;
      }

      if (minutes > 40 && minutes <= 50) {
        const _ = acc.find((a: any) => a.name === "40 - 50 min");

        _.value++;

        return acc;
      }

      if (minutes > 50 && minutes <= 60) {
        const _ = acc.find((a: any) => a.name === "50 - 60 min");

        _.value++;

        return acc;
      }

      return acc;
    },
    [
      { name: "0 - 10 min", value: 0 },
      { name: "10 - 20 min", value: 0 },
      { name: "20 - 30 min", value: 0 },
      { name: "30 - 40 min", value: 0 },
      { name: "40 - 50 min", value: 0 },
      { name: "50 - 60 min", value: 0 },
      { name: "above 60 min", value: 0 },
    ]
  );
};
