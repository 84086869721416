import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./start-page.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ArrowIcon } from "../../../Icons";
import { Link } from "react-router-dom";
import { Layout } from "../../../components/Layout/Layout";
import { MENU_ITEMS } from "../navigation/navigation-bar";

export const StartPage: React.FC<{}> = () => {
  return (
    <Layout>
      <div className="body-wrapper">
        <h1 className="title">{`Welcome to the Lapin Leadership Fingerprint${String.fromCodePoint(
          parseInt("2122", 16)
        )}
           Management Dashboard`}</h1>
        <div className="start-question">
          <span>What would you like to do?</span>
        </div>
        <div>
          {MENU_ITEMS.map((value, index) => {
            return (
              <SelectionRow
                key={`sel` + { index }}
                icon={value.icon}
                label={value.label}
                path={value.path}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

interface SelectionRowProps {
  icon: IconDefinition;
  label: string;
  path: string;
}

const SelectionRow: React.FC<SelectionRowProps> = ({ icon, label, path }) => {
  return (
    <Link className="link-no-underline" to={path}>
      <div className="start-page-select-row-flex">
        <div>
          <div style={{ width: "5.5rem", display: "inline-block" }}>
            <FontAwesomeIcon icon={icon} />
          </div>

          <span id="icon-label">{label}</span>
        </div>
        <div className="arrow-div">
          <ArrowIcon direction="right" color="#3c78e6" />
        </div>
      </div>
    </Link>
  );
};
