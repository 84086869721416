import { useLocation } from "react-router-dom";

import { RecoverPasswordForm } from "./recover-password-form";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const RecoverPasswordPage: React.FC<{}> = () => {
  let query = useQuery();
  return (
    <div className="request-password-wrapper-div">
      <div className="request-password-flex-wrapper">
        <span className="login-lapin-header">
          {`Lapin Leadership Fingerprint${String.fromCodePoint(
            parseInt("2122", 16)
          )}`}
        </span>
        <div style={{ marginTop: "10rem" }}>
          <span className="request-password-page-title">
            Password Recovery Form
          </span>

          <RecoverPasswordForm recoveryKey={query.get("key")} />
        </div>
      </div>
    </div>
  );
};
