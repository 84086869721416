import { useQuery } from "react-query";
import { getFingerprintData } from "../../services/fingerprintData.service";

export const useFingerprintData = () => {
  return useQuery({
    queryKey: ["fingerprintData"],
    queryFn: async () => {
      const response = await getFingerprintData();

      return response;
    },
  });
};

export const getGenders = (data: any) => {
  return data?.reduce(
    (acc: any, cur: any) => {
      const gender = cur?.gender;

      if (!gender) {
        return acc;
      }

      const _ = acc.find((o: any) => o.name === gender);

      if (_) {
        _.value += 1;
      }

      return acc;
    },
    [
      { name: "Male", value: 0 },
      { name: "Female", value: 0 },
      { name: "I prefer not to say", value: 0 },
    ]
  );
};

export const getIncome = (data: any) => {
  return data?.reduce(
    (acc: any, cur: any) => {
      const income = cur?.income;

      if (!income) {
        return acc;
      }

      const _ = acc.find((o: any) => {
        return o.name === income;
      });

      if (_) {
        _.value += 1;
      }

      return acc;
    },
    [
      { name: "Below $25,000", value: 0 },
      { name: "$25,000 - $49,999", value: 0 },
      { name: "$50,000 - $74,999", value: 0 },
      { name: "$75,000 - $99,999", value: 0 },
      { name: "$100,000 - $149,999", value: 0 },
      { name: "$150,000 - $199,999", value: 0 },
      { name: "$200,000 and above", value: 0 },
    ]
  );
};
