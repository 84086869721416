import { IOrderDto } from "./order.dto";
import { ISessionUserDto } from "./session-user.dto";

export enum LicenseStatus {
  USED = "used",
  ACTIVE = "active",
  COMPLETED = "completed",
}

export interface ILicenseDto {
  id?: number;
  orderId?: number;
  order?: IOrderDto;
  code: string;
  status: LicenseStatus;
  userId?: number;
  user?: ISessionUserDto;
  fingerprintDataId: number;
  narrativeId: number;
  activeUserId?: number;
}
