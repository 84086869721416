import React, { useState, useEffect } from "react";
import "../navigation/navigation-bar.scss";
import { IValueDto } from "../../../dtos/value.dto";
import { LoginHeader } from "../navigation/login-header";
import { Pagination, TitleRow } from "../user-management/user-management-page";
import { changePassword } from "../../../services/user.service";

import {
  addValue,
  deleteValues,
  editValue,
  getValues,
} from "../../../services/values.service";
import {
  DeleteValueModal,
  MessageBox,
} from "../../helpers/message-box/message-box";
import {
  IError,
  serverResponse,
  statusToVariant,
} from "../../../dtos/error.dto";
import { useErrorHandler } from "../../../hooks/error-hook";
import { Layout } from "../../../components/Layout/Layout";

export const ValuesLookUp: React.FC<{}> = () => {
  const values_per_page = 20;
  const [current_page, setCurrentPage] = useState<number>(1);
  const [total_pages, setTotalPages] = useState<number>(1);
  const [total_values, setTotalValues] = useState<Array<IValueDto>>([]);
  const [displayValues, setDisplayValues] = useState<Array<IValueDto>>([]);
  const [search_input, setSearchInput] = useState("");
  const [search, setSearch] = useState<Array<IValueDto>>([]);
  const [added, setAdded] = useState<boolean>(false);
  const [deleteArray, setDeleteArray] = useState<Array<number>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { httpError, updateHttpError } = useErrorHandler();

  const fetchValues = async () => {
    try {
      const result = await getValues();
      setTotalValues(result);
    } catch (error) {
      updateHttpError({
        message: (error as IError).messages[0],
        statusCode: (error as IError).statusCode,
      });
    }
  };

  useEffect(() => {
    fetchValues();
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(total_values.length / values_per_page));
  }, [total_values]);

  useEffect(() => {
    if (search_input === "") {
      selectPosts(total_values);
    } else {
      selectPosts(search);
    }
  }, [current_page, total_values]);

  useEffect(() => {
    fetchValues();
  }, [added]);

  const handleCheck = (id: number, checked: boolean) => {
    let newChecks = [...deleteArray];
    let toDelete = newChecks.includes(id);

    if (!checked) {
      newChecks.push(id);
    } else {
      if (toDelete) {
        newChecks = newChecks.filter((check) => check !== id);
      }
    }

    setDeleteArray([...newChecks]);
  };

  const selectPosts = (posts: Array<IValueDto>) => {
    const temp_array = posts.filter((element, index) => {
      if (current_page > 1) {
        if (
          index >= (current_page - 1) * values_per_page &&
          index <= current_page * values_per_page
        ) {
          return element;
        }
      } else {
        if (index < values_per_page) {
          return element;
        }
      }
    });
    setDisplayValues([...temp_array]);
  };

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const submitSearch = () => {
    let new_filter = total_values.filter((element, index) => {
      if (element.Phrase.toLowerCase().includes(search_input.toLowerCase())) {
        return element;
      }
    });
    setCurrentPage(1);

    setSearch([...new_filter]);
    setTotalPages(Math.ceil(new_filter.length / values_per_page));
  };

  useEffect(() => {
    selectPosts([...search]);
  }, [search]);

  const changePage = (direction: string) => {
    if (direction === "left") {
      if (current_page > 1) {
        setCurrentPage(current_page - 1);
      }
    } else if (direction === "right") {
      if (current_page < total_pages) {
        setCurrentPage(current_page + 1);
      }
    }
  };

  const handleAdded = () => {
    setAdded(!added);
  };

  const handleDelete = async () => {
    Promise.all(
      deleteArray.map((value, index) => {
        return deleteValues(value); //this is not synchronous
      })
    ).then((value) => {
      value.map((value, index) => {
        if (value.statusCode > 399) {
          updateHttpError({
            message: value.message,
            statusCode: value.statusCode,
          });
        }
      });
      setDeleteArray([]);
      setAdded(!added);
      setOpenModal(false);
      setTimeout(() => {
        updateHttpError({ message: "", statusCode: 0 });
      }, 3000);
    });
  };

  const handleCheckedAll = (checkedAll: boolean) => {
    let tempArray: number[] = [];

    if (!checkedAll) {
      total_values.map((value, index) => {
        tempArray.push(value.id);
      });
    }

    setDeleteArray([...tempArray]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const defineTotal = (): number => {
    let total = 0;
    if (search_input === "") {
      total = total_values.length;
    } else {
      total = search.length;
    }
    return total;
  };

  return (
    <Layout>
      {openModal && (
        <DeleteValueModal
          category={"value"}
          value_ids={deleteArray}
          onClose={handleCloseModal}
          onDelete={handleDelete}
        />
      )}

      <TitleRow
        title={"Values Management"}
        input={search_input}
        handleChange={handleInputChange}
        submitFunction={submitSearch}
        placeholder="Search values..."
      />
      {httpError.statusCode > 399 && (
        <MessageBox
          message={httpError.message}
          variant={statusToVariant(httpError.statusCode)}
        />
      )}
      <form className="user-management-form">
        <TableValues
          data={displayValues}
          handleCheck={handleCheck}
          deleteArray={deleteArray}
          selectAll={handleCheckedAll}
          handleAdded={handleAdded}
        />
      </form>

      <div className="add-license-wrapper">
        {deleteArray.length > 0 && (
          <button
            id="values-delete"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Delete
          </button>
        )}
        <EditValue
          label={"+ Add Value"}
          handleAdded={handleAdded}
          placeholder="Add New Value..."
          buttonLabel="Add"
          functionType="add"
        />
      </div>
      <Pagination
        total_pages={total_pages}
        current_page={current_page}
        total_users={defineTotal()}
        users_per_page={values_per_page}
        changePage={changePage}
      />
    </Layout>
  );
};

interface EditValueProps {
  label: string;
  placeholder: string;
  buttonLabel: string;
  functionType: string;
  id?: number;
  handleAdded(): void;
  handlePasswordChange?(response: serverResponse): void;
}

export const EditValue: React.FC<EditValueProps> = (props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [input, setInput] = useState<string>();
  const [httpResponse, setHttpResponse] = useState<serverResponse>();

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e: any) => {
    setInput(e.target.value);
  };

  const submitFunction = async (e: any) => {
    e.preventDefault();
    if (input !== "" && input !== undefined) {
      if (props.functionType === "edit") {
        if (props.id) {
          const result = await editValue(props.id, input);
          setHttpResponse(result);
        }
      } else if (props.functionType === "add") {
        const result = await addValue(input);
        setHttpResponse(result);
      } else if (props.functionType === "change") {
        if (props.id) {
          const result = await changePassword(props.id, input);
          if (props.handlePasswordChange) {
            props.handlePasswordChange(result);
          }
        }
      }

      setTimeout(() => {
        setEditMode(false);
        setHttpResponse(undefined);
        setInput("");
      }, 3000);

      props.handleAdded();
    }
  };

  return (
    <React.Fragment>
      {editMode === false && (
        <span className="edit-value" onClick={handleEdit}>
          {props.label}
        </span>
      )}
      <div className="float-right">
        {editMode === true && (
          <React.Fragment>
            {httpResponse !== undefined && (
              <span
                className={`response ${
                  httpResponse.statusCode > 400 ? `error` : ``
                }`}
              >
                {httpResponse.message}
              </span>
            )}
            <input
              type="text"
              placeholder={props.placeholder}
              value={input}
              onChange={handleChange}
              className="edit-spacing"
            />
            <button className="edit-spacing" onClick={submitFunction}>
              {props.buttonLabel}
            </button>
            <span className="edit-spacing cancel" onClick={handleEdit}>
              X
            </span>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

interface TableValuesProps {
  data: IValueDto[];
  deleteArray: number[];
  handleCheck(id: number, checked: boolean): void;
  handleAdded(): void;
  selectAll(checkedAll: boolean): void;
}

const TableValues: React.FC<TableValuesProps> = (props) => {
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  return (
    <React.Fragment>
      <table id="values">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                id="selectAllValues"
                onChange={() => {
                  setCheckedAll(!checkedAll);
                  props.selectAll(checkedAll);
                }}
                checked={checkedAll}
              />
            </th>
            <th>Phrase</th>
            <th className="count">Count</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((value, index) => {
            return (
              <ValueRow
                checked={props.deleteArray.includes(value.id)}
                phrase={value.Phrase}
                count={value.count}
                id={value.id}
                handleAdded={props.handleAdded}
                handleCheck={props.handleCheck}
              />
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

interface ValueRowProps {
  checked: boolean;
  phrase: string;
  count: number;
  id: number;
  handleAdded(): void;
  handleCheck(id: number, checked: boolean): void;
}

const ValueRow: React.FC<ValueRowProps> = (props) => {
  return (
    <tr>
      <td>
        <input
          onChange={() => {
            props.handleCheck(props.id, props.checked);
          }}
          type="checkbox"
          checked={props.checked}
        ></input>
      </td>
      <td className="phrase">{props.phrase}</td>
      <td className="count">{props.count}</td>
      <td className="edit">
        <EditValue
          label="Edit Phrase"
          id={props.id}
          handleAdded={props.handleAdded}
          placeholder="Edit Value..."
          buttonLabel="Edit"
          functionType="edit"
        />
      </td>
    </tr>
  );
};
