import ReactDom from "react-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

interface IMessageBoxProps {
  message: string;
  variant?: string;
}

interface IFormMessages {
  messages: string[];
  variant?: string;
}

interface IDeleteValue {
  value_ids: number[];
  category: string;
  onClose(): any;
  onDelete(): any;
}

interface IModal {
  message: string;
  onClose(): any;
  onConfirm(): any;
}

export const FormMessages = (props: IFormMessages) => {
  const { messages, variant = "successful" } = props;
  return (
    <Alert variant={variant}>
      <ul>
        {messages.map((message, idx) => (
          <li key={`msg-${idx}`}>{message}</li>
        ))}
      </ul>
    </Alert>
  );
};

export const MessageBox = (props: IMessageBoxProps) => {
  const { message, variant = "successful" } = props;

  return (
    <Alert variant={variant}>
      {/* <ul>
        {messages.map((message, idx) => (
          <li key={`msg-${idx}`}>{message}</li>
        ))}
      </ul> */}
      <span>{message}</span>
    </Alert>
  );
};

export const DeleteValueModal = (props: IDeleteValue) => {
  return ReactDom.createPortal(
    <div className="delete-overlay">
      <div className="delete-modal">
        <Container className="container-delete">
          <Row>
            <p>
              {props.value_ids.length < 2
                ? `Are you sure you want to remove this ${props.category}?`
                : `Are you sure you want to remove these ${props.category}s?`}
            </p>
          </Row>
          <Row>
            <Col>
              <Button onClick={props.onClose}>No</Button>
            </Col>
            <Col>
              <Button onClick={props.onDelete}>Yes</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>,
    document.getElementById("modal") as Element
  );
};

export const Modal = (props: IModal) => {
  return ReactDom.createPortal(
    <div className="delete-overlay">
      <div className="delete-modal">
        <Container className="container-delete">
          <Row>
            <p>{props.message}</p>
          </Row>
          <Row>
            <Col>
              <Button onClick={props.onClose}>No</Button>
            </Col>
            <Col>
              <Button onClick={props.onConfirm}>Yes</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>,
    document.getElementById("modal") as Element
  );
};
