import { IOrderDto } from "../../dtos/order.dto";

export function getUserIdsByCoupon(orders: IOrderDto[], couponIds: number[]) {
  // Filter orders by the coupon used
  const couponOrders = orders.filter((o) =>
    couponIds.includes(
      parseInt(o.processorLog.message?.replace(/[^0-9]/g, "") || "")
    )
  );

  const userIds = couponOrders.map((o) => o.ownerId);

  const uniqueUserIds = userIds.filter((u, i) => {
    return userIds.indexOf(u) === i;
  });

  return uniqueUserIds;
}

export function getByCoupon(
  data: unknown[] | undefined,
  userIds: (number | undefined)[] | undefined
) {
  return data?.filter((d: any) => {
    if (userIds?.length === 0) {
      return true;
    }

    return userIds?.includes(d.userId);
  });
}
