import API from "../../API";
import { useQuery } from "react-query";

export const useUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const { data } = await API.get("users/", {
        params: {
          limit: 1000,
        },
      });

      return data.result;
    },
  });
};
