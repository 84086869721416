import { IClassifierLabel } from "../dtos/classifier-label.dto";
import * as ClassifierServices from "../services/classifier.service";

export const classifierConstants = {
  LABELS_FETCH_REQUEST: "LABELS_GET_ALL_REQUEST",
  LABELS_FETCH_SUCCESS: "LABELS_GET_ALL_SUCCESS",
  LABELS_FETCH_FAILURE: "LABELS_GET_ALL_FAILURE",
};

interface IClassiferLablesState {
  lables: IClassifierLabel[];
  count: number;
  _fetched: boolean;
  _processing?: boolean;
  _errors?: string[];
}

const initialState: IClassiferLablesState = {
  lables: [],
  count: 0,
  _fetched: false,
};

export const classiferLables = (
  state = initialState,
  action: { type: string; payload: any }
): IClassiferLablesState => {
  switch (action.type) {
    case classifierConstants.LABELS_FETCH_REQUEST:
      return {
        lables: [],
        count: 0,
        _fetched: true,
        _processing: true,
      };
    case classifierConstants.LABELS_FETCH_SUCCESS:
      return {
        lables: action.payload.result,
        count: action.payload.count,
        _processing: false,
        _fetched: true,
      };
    case classifierConstants.LABELS_FETCH_FAILURE:
      return {
        lables: [],
        count: 0,
        _processing: false,
        _fetched: false,
        _errors: action.payload,
      };
    default:
      return state;
  }
};

const fetchLabels = () => {
  const request = () => {
    return { type: classifierConstants.LABELS_FETCH_REQUEST };
  };

  const success = (result: any) => {
    return {
      type: classifierConstants.LABELS_FETCH_SUCCESS,
      payload: result,
    };
  };
  const failure = (error: any) => {
    return { type: classifierConstants.LABELS_FETCH_FAILURE, payload: error };
  };

  return async (dispatch: any) => {
    dispatch(request());

    try {
      const data = await ClassifierServices.getLabels();
      dispatch(success(data));
    } catch (e) {
      dispatch(failure([e.message]));
    }
  };
};

export const classiferActions = {
  fetchLabels,
};
