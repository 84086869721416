import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";

import { authentication, userActions } from "../actions/login.action";
import { classiferLables } from "./../actions/classifier.action";
import { editHttpError, errorActions } from "./../actions/error.action";

const rootReducer = combineReducers({
  authentication,
  classiferLables,
  editHttpError,
});

const loggerMiddleware = createLogger();

export const allActions = {
  userActions,
  errorActions,
};

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
