export interface IError {
  statusCode: number;
  message: Array<string>;
  messages: Array<string>;
  path: string;
  errors: Array<string>;
  status: number;
  timestamp: Date;
}

export interface serverResponse {
  statusCode: number;
  message: string;
}

export const statusToVariant = (statusCode: number): string => {
  let variant: string = "";
  if (statusCode > 399) {
    variant = "danger";
  }
  if (statusCode < 300 && statusCode > 200) {
    variant = "success";
  }
  return variant;
};
